export function search_filter() {

  const listItem = document.getElementsByClassName('company-list_item');
  let resultText = document.getElementById('filter_result');

  function displayJudge() {
    let displayCount = 0;

    for (let i = 0, len = listItem.length; i < len; i++) {
      let elm = listItem[i];
      let display;
      let setTime;


      display = (
        elm.dataset.area_display === '1' &&
        elm.dataset.hole_display === '1' &&
        elm.dataset.service_display === '1' &&
        elm.dataset.facility_display === '1' &&
        elm.dataset.price_display === '1' &&
        elm.dataset.parking_display === '1' &&
        elm.dataset.trial_display === '1'
      );

      if (display === false){
        elm.style.setProperty('display', 'none');
      }
      else {
        elm.style.setProperty('display', null);

        if (displayCount * 100 <= 1000) {
          setTime = displayCount * 100;
        } else {
          setTime = 2000;
        }


        setTimeout(function () {
          elm.classList.remove('hide');
        }, setTime);

        displayCount++;
      }
    }

    resultText.innerHTML = '<span class="num">'+displayCount+'</span>件の検索結果が見つかりました。';
  }

  function hideAll() {
    for (let i = 0; i < listItem.length; i++) {
      listItem[i].classList.add('hide');
    }
  }

  // 初期
  window.addEventListener('DOMContentLoaded',function (){
    hideAll();
    displayJudge();

    resultText.innerText = null;
  })

  // リセット
  document.getElementById('filter_reset').addEventListener('click',function (){
    for (let i = 0,len = listItem.length; i < len; i++){
      let elm = listItem[i];

      elm.dataset.area_display = '1';
      elm.dataset.hole_display = '1';
      elm.dataset.service_display = '1';
      elm.dataset.facility_display = '1';
      elm.dataset.price_display = '1';
      elm.dataset.parking_display = '1';
      elm.dataset.trial_display = '1';
    }

    let filterCheckboxes = document.querySelectorAll('#slide-menu input[type="checkbox"]');
    let filterSelect = document.querySelectorAll('#slide-menu select');
    let filterRadio = document.querySelectorAll('#slide-menu input[type="radio"]');

    for (let i = 0,len = filterCheckboxes.length; i < len; i++){
      filterCheckboxes[i].checked = false;
    }

    for (let i = 0,len = filterSelect.length; i < len; i++){
      filterSelect[i].value = '';
    }

    for (let i = 0,len = filterRadio.length; i < len; i++){
      if ( filterRadio[i].value === '' ) {
        filterRadio[i].checked = true
      }
    }

    hideAll();
    displayJudge();

    resultText.innerText = null;
  })

  // 都道府県
  //-------------------------------------------

  function set_prefectures_filter_state() {
    let prefecturesList = document.getElementsByName('filter_prefectures');
    let activePrefecturesList = [];

    for (let i = 0, len = prefecturesList.length; i < len; i++) {
      if (!prefecturesList[i].checked) continue;

      activePrefecturesList.push(prefecturesList[i].value);
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmPrefectures = elm.dataset.prefectures;

      if (
        activePrefecturesList.indexOf(elmPrefectures) >= 0 ||
        activePrefecturesList.length === 0
      ) {
        elm.dataset.prefectures_display = '1';
      } else {
        elm.dataset.prefectures_display = '0';
      }
    }
  }

  const filter_prefectures = document.getElementById('filter_prefectures');

  filter_prefectures.addEventListener('change', function () {
    hideAll();
    set_prefectures_filter_state();
    displayJudge();
  })
  
  // エリア
  //-------------------------------------------

  function set_area_filter_state() {
    let areaList = document.getElementsByName('filter_area');
    let activeAreaList = [];

    for (let i = 0, len = areaList.length; i < len; i++) {
      if (!areaList[i].checked) continue;

      activeAreaList.push(areaList[i].value);
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmArea = elm.dataset.area;

      if (
        activeAreaList.indexOf(elmArea) >= 0 ||
        activeAreaList.length === 0
      ) {
        elm.dataset.area_display = '1';
      } else {
        elm.dataset.area_display = '0';
      }
    }
  }

  const filter_area = document.getElementById('filter_area');

  filter_area.addEventListener('change', function () {
    hideAll();
    set_area_filter_state();
    displayJudge();
  })


  // ホール数
  //-------------------------------------------

  function set_hole_filter_state() {
    let holeValue = document.getElementsByName('hole')[0].value;

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmHole = elm.dataset.hole;

      if (
        (holeValue === elmHole) ||
        ( elmHole.indexOf('~') === 0 && (holeValue.replace('~') <= elmHole) ) ||
        !holeValue
      ) {
        elm.dataset.hole_display = '1';
      } else {
        elm.dataset.hole_display = '0';
      }
    }
  }

  const filter_hole = document.getElementById('filter_hole');

  filter_hole.addEventListener('change', function () {
    hideAll();
    set_hole_filter_state();
    displayJudge();
  })


  // 対応サービス
  //-------------------------------------------

  function set_service_filter_state() {
    let serviceList = document.getElementsByName('service');
    let activeServiceList = [];

    for (let i = 0, len = serviceList.length; i < len; i++) {
      if (!serviceList[i].checked) continue;

      activeServiceList.push(serviceList[i].value);
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmServiceList = elm.dataset.service.split(' ');

      elm.dataset.service_display = '1';

      if (activeServiceList.length === 0){
        elm.dataset.service_display = '1';
        continue;
      }

      for (let k = 0,len = activeServiceList.length; k < len; k++){
        let activeService = activeServiceList[k];

        if ( !elmServiceList.find(value => value === activeService ) ){
          elm.dataset.service_display = '0';
        }
      }
    }
  }

  const filter_service = document.getElementById('filter_service');

  filter_service.addEventListener('change', function () {
    hideAll();
    set_service_filter_state();
    displayJudge();
  })


  // 価格帯
  //-------------------------------------------

  function set_price_filter_state() {
    let priceList = document.getElementsByName('price');
    let activePriceList = [];

    for (let i = 0, len = priceList.length; i < len; i++) {
      if (!priceList[i].checked) continue;

      activePriceList.push(priceList[i].value);
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmPriceList = elm.dataset.price.split(' ');

      elm.dataset.price_display = '0';

      if (activePriceList.length === 0){
        elm.dataset.price_display = '1';
        continue;
      }

      for (let k = 0,len = activePriceList.length; k < len; k++){
        let activePrice = activePriceList[k];

        if ( elmPriceList.find(value => value === activePrice ) ){
          elm.dataset.price_display = '1';
        }
      }
    }
  }

  const filter_price = document.getElementById('filter_price');

  filter_price.addEventListener('change', function () {
    hideAll();
    set_price_filter_state();
    displayJudge();
  })


  // 施設設備
  //-------------------------------------------

  function set_facility_filter_state() {
    let facilityList = document.getElementsByName('facility');
    let activeFacilityList = [];

    for (let i = 0, len = facilityList.length; i < len; i++) {
      if (!facilityList[i].checked) continue;

      activeFacilityList.push(facilityList[i].value);
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmFacilityList = elm.dataset.facility.split(' ');

      elm.dataset.facility_display = '1';

      if (activeFacilityList.length === 0){
        elm.dataset.facility_display = '1';
        continue;
      }

      for (let k = 0,len = activeFacilityList.length; k < len; k++){
        let activefacility = activeFacilityList[k];

        if ( !elmFacilityList.find(value => value === activefacility ) ){
          elm.dataset.facility_display = '0';
        }
      }
    }
  }

  const filter_facility = document.getElementById('filter_facility');

  filter_facility.addEventListener('change', function () {
    hideAll();
    set_facility_filter_state();
    displayJudge();
  })


  // 駐車場台数
  //-------------------------------------------

  function set_parking_filter_state() {
    let parkingValue = document.getElementsByName('parking')[0].value;
    let filter_value;

    if ( parkingValue ) {
      filter_value = parkingValue.split('~');
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmParking = elm.dataset.parking;

      if (!parkingValue) {
        elm.dataset.parking_display = '1';
        continue;
      }

      if (
        ( filter_value.length === 1 && elmParking !== '' && elmParking === filter_value[0] ) ||
        ( filter_value.length === 2 && elmParking !== '' && filter_value[0] <= elmParking && elmParking <= filter_value[1] )
      ) {
        elm.dataset.parking_display = '1';
      } else {
        elm.dataset.parking_display = '0';
      }
    }
  }

  const filter_parking = document.getElementById('filter_parking');

  filter_parking.addEventListener('change', function () {
    hideAll();
    set_parking_filter_state();
    displayJudge();
  })


  // 見学・相談会
  //-------------------------------------------

  function set_trial_filter_state() {
    let trialValue = document.getElementsByName('trial');
    let filter_value;

    for (let i = 0,len = trialValue.length; i < len; i++){
        let elm = trialValue[i];

        if ( elm.checked ) {
          filter_value = elm.value;
        }
    }

    for (let j = 0, len = listItem.length; j < len; j++) {
      let elm = listItem[j];
      let elmTrial = elm.dataset.trial;

      if (
        (filter_value === 'exist' && elmTrial) ||
        !filter_value
      ) {
        elm.dataset.trial_display = '1';
      } else {
        elm.dataset.trial_display = '0';
      }
    }
  }

  const filter_trial = document.getElementById('filter_trial');

  filter_trial.addEventListener('change', function () {
    hideAll();
    set_trial_filter_state();
    displayJudge();
  })

}