import {search_filter} from './modules/search_filter';
if (document.getElementById('slide-menu')){
    search_filter();
}


const slideMenu = document.getElementById('slide-menu');
if (slideMenu) {
    const slideMenu_switch = document.getElementById('slide-menu_switch');

    if (document.getElementById('slide-menu')) {
        slideMenu_switch.addEventListener('click', function () {
            slideMenu.classList.toggle('open');
        })
    }
}

//トップ・県別一覧でのみ実行
if (
  document.body.classList.contains('home') ||
  document.body.classList.contains('archive')
){
    const companyBtn = document.getElementById('modeSwitch_company');
    const recruitBtn = document.getElementById('modeSwitch_recruit');
    const currentClass = 'current';

    if (companyBtn){
        companyBtn.addEventListener('click', function () {
            if (!this.classList.contains(currentClass)){
                this.classList.add(currentClass);
                recruitBtn.classList.remove(currentClass)
                document.body.classList.remove('mode-recruit')
            }

        })
    }

    if (recruitBtn){
        recruitBtn.addEventListener('click', function () {
            if (!this.classList.contains(currentClass)){
                this.classList.add(currentClass);
                companyBtn.classList.remove(currentClass);
                document.body.classList.add('mode-recruit')
            }
        })
    }
}

const btn = document.getElementById('scrollTop');
if (btn) {
    let scrollTop;

    window.addEventListener('scroll', function () {
        scrollTop = window.pageYOffset;

        if (scrollTop >= 600) {
            btn.style.opacity = '1';
            btn.style.pointerEvents = null;
        } else {
            btn.style.opacity = '0';
            btn.style.pointerEvents = 'none';
        }

    })


    btn.addEventListener('click', function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    })
}

//BBSを取得しているページのみで実行
if (
  document.getElementsByClassName('singleContent_blog') &&
  document.body.classList.contains('single')
){
    let bbsContents = document.getElementsByClassName('singleContent_blog');
    let rightCulumn = document.getElementsByClassName('singleContent_r');

    if (window.matchMedia('(max-width: 767px)').matches) {

        rightCulumn[0].appendChild(
          bbsContents[0]
        );
    }
}



